import React, { useRef, useEffect, useState } from "react";
import SearchBar from "./components/SearchBar"
import _ from "lodash"

export interface data {
    id: number,
    title: string,
    artist_title: string,
    place_of_origin: string,
    credit_line: string,
    image: string,
    thumbnail: {
        alt_test: string,
    },
    info: {
        license_text: string,
        license_links: string[],
    },
}

export const App = () => {
    const divRef = useRef<any>(null);
    const formRef = useRef<any>(null);
    const [data, setData] = useState<data | undefined>();

    useEffect(() => {
        async function parse() {
            await fetch("https://artsalononclick.xyz/quote/random-artwork-api")
                .then(res => res.json())
                .then((result) => {
                    setData(result);
                    console.log(result);
                },
                    (error) => { }
                )
        }
        if (_.isEmpty(data)) {
            parse();
        }
    }, [data])

    return (
        <div className="w-screen h-screen overflow-none bg-gray-700">
            {data &&
                <div
                    className="absolute z-0 top-0 bottom-0 right-0 left-0"
                    style={{
                        backgroundImage: `url(${data.image})`,
                        backgroundPosition: `center`,
                        backgroundSize: `cover`,
                        backgroundRepeat: `norepeat`,
                    }}
                >
                    <img className="absolute top-2 right-2 z-10 h-20 w-20" src="./img/articedu.jpg" alt="" />
                    <div className="absolute top-0 left-0 bg-white py-2 px-5 bg-opacity-40 backdrop-filter backdrop-blur-sm rounded-br-3xl">
                        <span className="text-gray-900 font-bold text-lg truncate-1-lines select-none hover:underline"><a href={`https://www.artic.edu/artworks/${data.id}`} target="_blank" rel="noreferrer">{data.title}</a></span>
                        <span className="text-gray-900 font-light text-base truncate-1-lines select-none pointer-events-none">{data.artist_title}</span>
                        <span className="text-gray-900 font-extralight text-sm mt-1 tracking-widest truncate-1-lines select-none pointer-events-none">{data.place_of_origin}</span>
                    </div>
                    <div className="absolute bottom-0 left-0 bg-white py-2 px-5 bg-opacity-40 backdrop-filter backdrop-blur-sm rounded-tr-3xl">
                        <span className="text-gray-900 font-light truncate-1-lines select-none pointer-events-none">{data.credit_line}</span>
                        <span className="text-gray-900 font-light underline truncate-1-lines"><a href={data.info.license_links.pop()} rel="noreferrer" target="_blank">{data.info.license_text}</a></span>
                    </div>
                </div>
            }
            <div ref={divRef} className={`w-screen h-screen flex flex-col items-center justify-center z-50`} onClick={() => { document.querySelector('input')?.focus(); localStorage.setItem('autocomplete', ''); }}>
                <form ref={formRef} className="w-full z-10 px-5" action="https://artsalononclick.xyz/s" method="GET">
                    <div className="relative flex flex-row transition-all bg-white w-full max-w-5xl mx-auto rounded-lg hover:shadow-2xl focus-within:shadow-2xl ring-1 ring-gray-500 focus-within:ring-gray-700">
                        <SearchBar formRef={formRef} />
                        <button className="transition-all rounded-sm hover:bg-gray-100 active:outline-none focus:outline-none p-2" type="submit">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
                            </svg>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
};
